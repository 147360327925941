import React from 'react';
import Layout from '../components/Layout';
import ConfirmPayment from '../sections/ConfirmPayment';

const ConfirmPaymentPage = () => (
  <Layout>
    <ConfirmPayment />
  </Layout>
);

export default ConfirmPaymentPage;
