import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex, Box, Button, Image, Text } from 'rebass/styled-components';
import { navigate } from 'gatsby';
import StyledRectangle from '../components/StyledRectangle';
import StepsHeader from '../components/StepsHeader';
import PackageBanner from '../components/PackageBanner';
import { Link as GatsbyLink } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { STEPS_CHECKOUT, STORAGE_KEYS } from '../resources/constants';
import checkCircle from '../../assets/images/check-circle.svg';

const ConfirmPayment = () => {
  const [packageData, setPackageData] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.PACKAGE_DATA))
      navigate('/next-steps');
    else setPackageData(true);
  }, []);

  const data_content = useStaticQuery(
    graphql`
      query MyQueryConfirmPayment {
        contentfulButton {
          contentful_id
          text
          url
          urlInternal
        }
      }
    `
  );

  const handleTrackCustomEvent = actionName => {
    trackCustomEvent({
      category: 'confirmPayment',
      action: actionName
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    navigate(`/`);
  };

  if (!packageData) return null;
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      maxWidth="550px"
      width="100%"
      mb={5}
      mt={12}
    >
      <StepsHeader title="Checkout" currentStep="3" steps={STEPS_CHECKOUT} />

      <Box my={2} width={1}>
        <PackageBanner />
      </Box>

      <Box as="form" id="CreateAccountForm" onSubmit={handleSubmit} width={1}>
        <StyledRectangle bg="white" py={[10, 12]} width={1}>
          <Box px={5}>
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                src={checkCircle}
                alt="check-circle"
                height="50px"
                width="50px"
              ></Image>
              <Text textAlign="center" fontSize={10} my={5}>
                Payment Succesful!
              </Text>
              <Text textAlign="center">
                {' '}
                Our team will be in touch with the next steps to getting started
                soon.
              </Text>
            </Flex>
          </Box>
        </StyledRectangle>
        <Flex
          justifyContent="center"
          width={1}
          px={10}
          pt={5}
          sx={{
            paddingBottom: '100px'
          }}
        >
          <GatsbyLink
            to={
              data_content.contentfulButton.urlInternal
                ? data_content.contentfulButton.urlInternal
                : data_content.contentfulButton.url
            }
            target={data_content.contentfulButton.urlInternal ? '' : '_blank'}
          >
            <Button
              width="180px"
              variant="bDarkRed"
              onClick={() =>
                handleTrackCustomEvent(data_content.contentfulButton.text)
              }
            >
              {data_content.contentfulButton.text}
            </Button>
          </GatsbyLink>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ConfirmPayment;
